import {
  CONST_APIURLKUSALA,

} from "../../Constants.js";
import axios from "axios";
import { getUserToken } from "../Login/LoginActions";
// import { fetchOrders } from "./OrderSearchActions"

/**
 * Get a product
 * @param {*} productID 
 */
export function getProduct(productID) {
  return (dispatch) => {
    console.log("getProductBegin : " + productID);
    dispatch(getProductBegin());
    dispatch(getProductRegisteredinit());
    return (
      axios
        .get(
          CONST_APIURLKUSALA +
          "products/" +
          productID +
          "?DOLAPIKEY=" +
          getUserToken()
        )
        .then((json) => {
          console.log("getProductSuccess");

          dispatch(getProductSuccess(json.data));
          if (json.data.ref.startsWith("STA"))
            dispatch(getProductFullRegistered(json.data.id));
        })
        .catch((error) => {
          console.log("getProductFailure");
          if (error.response) {
            if (error.response.status === 404) {
              dispatch(getProductSuccess([]));
            } else {
              dispatch(getProductFailure(error.response.data.error));
            }
          } else {
            // *** It's an API error
            dispatch(getProductFailure(error));
          }
        })
    );
  };
}

export const GET_PRODUCT_BEGIN = "GET_PRODUCT_BEGIN";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAILURE = "GET_PRODUCT_FAILURE";

export const getProductBegin = () => ({
  type: GET_PRODUCT_BEGIN,
});

export const getProductSuccess = (product) => ({
  type: GET_PRODUCT_SUCCESS,
  payload: { product },
});

export const getProductFailure = (error) => ({
  type: GET_PRODUCT_FAILURE,
  payload: { error },
});





// ***********************************************************
/**
 * Get the order lines that contain a product
 * @param {*} productID 
 */
export function getProductRegistered(productID) {
  return (dispatch) => {
    console.log("getProductRegistered: " + productID);
    dispatch(getProductRegisteredBegin());


    return (
      axios
        .get(
          CONST_APIURLKUSALA +
          "dklaccueil/" +
          productID +
          "/registered" +
          "?DOLAPIKEY=" +
          getUserToken()

        )
        .then((json) => {
          console.log("getProductRegisteredSuccess");
          dispatch(getProductRegisteredSuccess(json.data));

          // let resultTab = json.data.map(element => element.fk_commande).join(',');

          // dispatch(fetchOrders("", 'orderidslist', resultTab));
        })

        .catch((error) => {
          console.log("getProductRegisteredFailure");
          // *** 
          if (error.response) {
            if (error.response.status === 404) {
              dispatch(getProductRegisteredSuccess([]));
            } else {
              dispatch(getProductRegisteredFailure(error.response.data.error));
            }
          } else {
            // *** It's an API error
            dispatch(getProductRegisteredFailure(error));
          }
        })
    );
  };
}

export const GET_PRODUCTREGISTERED_BEGIN = "GET_PRODUCTREGISTERED_BEGIN";
export const GET_PRODUCTREGISTERED_SUCCESS = "GET_PRODUCTREGISTERED_SUCCESS";
export const GET_PRODUCTREGISTERED_FAILURE = "GET_PRODUCTREGISTERED_FAILURE";
export const GET_PRODUCTREGISTERED_INIT = "GET_PRODUCTREGISTERED_INIT";

export const getProductRegisteredBegin = () => ({
  type: GET_PRODUCTREGISTERED_BEGIN,
});

export const getProductRegisteredSuccess = (productregistered) => ({
  type: GET_PRODUCTREGISTERED_SUCCESS,
  payload: { productregistered },
});

export const getProductRegisteredFailure = (error) => ({
  type: GET_PRODUCTREGISTERED_FAILURE,
  payload: { error },
});

export const getProductRegisteredinit = () => ({
  type: GET_PRODUCTREGISTERED_INIT,
  payload: {},
});



// ***********************************************************
/**
 * Get the order lines that contain a product
 * @param {*} productID 
 */
export function getProductFullRegistered(productID) {
  return (dispatch) => {
    console.log("getProductFullRegistered: " + productID);
    dispatch(getProductFullRegisteredBegin());


    return (
      axios
        .get(
          CONST_APIURLKUSALA +
          "dklaccueil/fullregistered?productId=" +
          productID +
          "&DOLAPIKEY=" +
          getUserToken()

        )
        .then((json) => {
          console.log("getProductFullRegisteredSuccess");
          dispatch(getProductFullRegisteredSuccess(json.data));


        })

        .catch((error) => {
          console.log("getProductFullRegisteredFailure");
          // *** 
          if (error.response) {
            if (error.response.status === 404) {
              dispatch(getProductFullRegisteredSuccess([]));
            } else {
              dispatch(getProductFullRegisteredFailure(error.response.data.error));
            }
          } else {
            // *** It's an API error
            dispatch(getProductFullRegisteredFailure(error));
          }
        })
    );
  };
}

export const GET_PRODUCTFULLREGISTERED_BEGIN = "GET_PRODUCTFULLREGISTERED_BEGIN";
export const GET_PRODUCTFULLREGISTERED_SUCCESS = "GET_PRODUCTFULLREGISTERED_SUCCESS";
export const GET_PRODUCTFULLREGISTERED_FAILURE = "GET_PRODUCTFULLREGISTERED_FAILURE";
export const GET_PRODUCTFULLREGISTERED_INIT = "GET_PRODUCTFULLREGISTERED_INIT";

export const getProductFullRegisteredBegin = () => ({
  type: GET_PRODUCTFULLREGISTERED_BEGIN,
});

export const getProductFullRegisteredSuccess = (productFullregistered) => ({
  type: GET_PRODUCTFULLREGISTERED_SUCCESS,
  payload: { productFullregistered },
});

export const getProductFullRegisteredFailure = (error) => ({
  type: GET_PRODUCTFULLREGISTERED_FAILURE,
  payload: { error },
});

export const getProductFullRegisteredinit = () => ({
  type: GET_PRODUCTFULLREGISTERED_INIT,
  payload: {},
});