import React from "react";

import TextField from "@material-ui/core/TextField"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import { Card, CardContent } from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import Autocomplete from "@material-ui/lab/Autocomplete"
import FormHelperText from "@material-ui/core/FormHelperText"
import Grid from '@material-ui/core/Grid';
import { useTranslation } from "react-i18next";
// *** Redux
import { useDispatch } from 'react-redux';
import { shallowEqual, useSelector } from 'react-redux';
import { useState, useEffect } from "react"

// *** Zopa components
import { getProducts } from "../../services/CommonProduct/CommonProductActions"
// import { getCountriesTable } from "../../services/GlobalLists/GlobalListsActions"
import { setReduxinputsChanged, setReduxCustomer } from "../../services/services/RegistrationActions"

// *** Styling
// import clsx from "clsx";
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles((theme) => ({
    root: {
        background: "linear-gradient(45deg, #F4F6F6 30%, #F2F3F4 90%)",
        padding: theme.spacing(2),
    },
    pageTitle: {
        color: "#6b6c6d"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    blockCardHeaderStyle:
        theme.palette.blockCardHeaderStyle,
    blockCardHeaderIcon:
        theme.palette.blockCardHeaderIcon

}));
export default function IdentityForm(props) {
    const { ...rest } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // *** Redux state
    const civilities = useSelector(state => state.globalListsReducer.civilities, shallowEqual);
    const incomelevels = useSelector(state => state.globalListsReducer.incomelevels, shallowEqual);

    const [loccustomer, setLoccustomer] = useState({});
    const [firstname, setfirstname] = useState("");
    const [lastname, setlastname] = useState("");
    const [civility, setcivility] = useState("");
    const [email, setemail] = useState("");
    const [emailbis, setemailbis] = useState("");

    const [subsproductId, setsubsproductId] = useState(null);
    const [incomeLevel, setIncomeLevel] = useState(null);

    const subscriptionProducts = useSelector(state => state.commonProductReducer.products.filter(product => product.ref.startsWith("ADH")));

    useEffect(() => {
        dispatch(getProducts());
    }, []);

    useEffect(() => {
        loccustomer.firstname = firstname;
        loccustomer.lastname = lastname;
        loccustomer.email = email;
        loccustomer.emailbis = emailbis;
        loccustomer.civility = civility;
        loccustomer.subsproductId = subsproductId;
        loccustomer.price_level = incomeLevel;

        setLoccustomer(loccustomer);
        dispatch(setReduxCustomer(loccustomer));
        dispatch(setReduxinputsChanged(true));

    }, [lastname, firstname, email, civility, emailbis, incomeLevel, subsproductId]);

    return (
        <Card style={{ margin: "10px" }}>
            <CardHeader title={t('identity.title')} subheader="" style={{ backgroundColor: "#DFDFDB" }}>
            </CardHeader>
            <CardContent>
                {/* <form className={classes.root} noValidate autoComplete="off"> */}
                <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item md={1} xs={2}>
                        {civilities &&
                            <Select label={t('identity.fieldcivility')} selectedIndex={civility} name="civility"
                                value={civility}
                                onChange={(event) =>
                                    setcivility(event.target.value)}
                                style={{ marginTop: "21px" }}
                            >
                                {civilities.map(civility =>
                                    <MenuItem value={civility.rowid} key={civility.rowid}>{civility.code}</MenuItem>
                                )}
                            </Select>
                        }
                        <FormHelperText>{t('identity.fieldcivility')}</FormHelperText>
                    </Grid>
                    <Grid item md={5} xs={10}>
                        <TextField
                            fullWidth
                            label={t('identity.fieldlastname')}
                            margin="dense"
                            name="lastname"
                            onChange={(event) =>
                                setlastname(event.target.value)}
                            required
                            value={lastname}
                            style={{ marginLeft: "5px" }}
                            helperText={t('identity.fieldlastname')}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            fullWidth
                            label={t('identity.fieldfirstname')}
                            margin="dense"
                            name="firstname"
                            onChange={(event) =>
                                setfirstname(event.target.value)}
                            required
                            value={firstname}
                            style={{ marginLeft: "5px" }}
                            helperText={t('identity.fieldfirstname')}
                        />
                    </Grid>
                </Grid>

                <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item md={6} xs={12}>
                        <TextField
                            fullWidth
                            label={t('identity.fieldemail')}
                            margin="dense"
                            type="email"
                            name="email"
                            onChange={(event) =>
                                setemail(event.target.value)}
                            value={email}
                            required
                            helperText={t('identity.fieldemailhelper')}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            fullWidth
                            label={t('identity.fieldemail')}
                            margin="dense"
                            type="email"
                            name="emailbis"
                            onChange={(event) =>
                                setemailbis(event.target.value)}
                            value={emailbis}
                            required
                            helperText={t('identity.fieldemailbishelper')}
                        />
                    </Grid>

                </Grid>
                <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item md={6} xs={12}>
                        <Autocomplete
                            id="subsproductId"

                            options={incomelevels}
                            getOptionLabel={(incomelevel) => incomelevel.label.substring(2)}
                            style={{ marginTop: "20" }}
                            name="subsproductId"
                            onChange={(event, newValue) => {
                                setIncomeLevel(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('identity.incomelevel')}
                                    name="test2"
                                    fullWidth
                                    helperText={t('identity.incomelevelHelper')}
                                />
                            )}
                        />
                    </Grid>

                    {subscriptionProducts.length > 0 && <Grid item md={6} xs={12}>
                        <Autocomplete
                            id="subsproductId"
                            options={subscriptionProducts}
                            getOptionLabel={(option) => option.label}
                            style={{ marginTop: "20" }}
                            name="subsproductId"
                            onChange={(event, newValue) => {
                                setsubsproductId(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('identity.fieldsubscribtion')}
                                    name="test2"
                                    fullWidth
                                    helperText={t('identity.fieldsubscribtionhelsper')}
                                />
                            )}
                        />
                    </Grid>}
                </Grid>
            </CardContent>
        </Card >
    )
};
