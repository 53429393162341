import React from "react";

import Typography from "@material-ui/core/Typography";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField"
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useConfirm } from 'material-ui-confirm';
import OrderContent from "./OrderContent"
import { useTranslation } from "react-i18next";

// *** Redux
import { useDispatch } from 'react-redux';
import { shallowEqual, useSelector } from 'react-redux';
import { useEffect } from "react"

// *** Zopa components
import { validateGlobalRegistration, setIsAllreadyRegistered, setregistrationError, setIsRegistrationValidated, setReduxinputsChanged, setDonationAmount } from "../../services/services/RegistrationActions"
import { getConfiguration, getConfigurationdepositpercent } from "../../services/GlobalLists/GlobalListsActions";

// *** Styling
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        background: "linear-gradient(45deg, #F4F6F6 30%, #F2F3F4 90%)",
        padding: theme.spacing(2),
    },
    pageTitle: { color: "#6b6c6d" },
    blockCardHeaderStyle:
        theme.palette.blockCardHeaderStyle,
    blockCardHeaderIcon:
        theme.palette.blockCardHeaderIcon
}));

export default function ValidationForm(props) {

    const classes = useStyles();
    const dispatch = useDispatch();
    const confirm = useConfirm();
    const { t, i18n } = useTranslation();

    // *** Redux state
    const registrationError = useSelector(state => state.registrationReducer.registrationError, shallowEqual);
    const loccomputedOrderlines = useSelector(state => state.registrationReducer.computedOrderlines, shallowEqual);
    const isAllreadyRegistrered = useSelector(state => state.registrationReducer.isAllreadyRegistrered, shallowEqual);
    const orders = useSelector(state => state.productReducer.productFullregistered, shallowEqual);
    const isRegistrationValidated = useSelector(state => state.registrationReducer.isRegistrationValidated, shallowEqual);
    const reduxcustomer = useSelector(state => state.registrationReducer.loccustomer, shallowEqual);
    const inputsChanged = useSelector(state => state.registrationReducer.inputsChanged, shallowEqual);
    const reduxregistration = useSelector(state => state.registrationReducer.registration, shallowEqual);
    const courseHasChanged = useSelector(state => state.registrationReducer.courseHasChanged, shallowEqual);

    const withoutdeposit = reduxregistration && reduxregistration.product && reduxregistration.product.array_options && reduxregistration.product.array_options.options_sta_withoutdeposit;
    const reduxdonation = useSelector(state => state.registrationReducer.donationamount, shallowEqual);
    const [localdonationamount, setlocaldonationamount] = React.useState(null);
    const displaydonation = reduxregistration && reduxregistration.product && reduxregistration.product.array_options && reduxregistration.product.array_options.options_sta_withoutdonation;
    //  const displaydonationoptions = reduxregistration && reduxregistration.product && reduxregistration.product.array_options;
    /**
      * If there's a registration Error, we open a dialog to show the error
      */
    useEffect(() => {
        if (registrationError !== "") {
            confirm({
                description: registrationError.message,
                title: t('validation.titleerror'),
                cancellationText: "",
                confirmationButtonProps: { autoFocus: true }
            })
        }
    }, [registrationError]);

    /**
     * && (isSessionFull === false)
     */
    useEffect(() => {
        /*     if (!loccomputedOrderlines.reduce((a, b) => a + parseFloat(b.total_ttc), 0) > 0) {
                confirm({
                    description: "Cette commande est à 0 Euros",
                    title: '',
                    cancellationText: "",
                    confirmationButtonProps: { autoFocus: true }
                })
                return;
            }
     */
        if (isAllreadyRegistrered === false && (registrationError === "")
            // && (loccomputedOrderlines.reduce((a, b) => a + parseFloat(b.total_ttc), 0) > 0)
            && (loccomputedOrderlines.length > 0)
            && (!inputsChanged))
            dispatch(setIsRegistrationValidated(true));
        else
            dispatch(setIsRegistrationValidated(false));
    }, [loccomputedOrderlines, registrationError, isAllreadyRegistrered, inputsChanged]);


    //*** COurse has changed we reset the donation */
    useEffect(() => {
        // if (courseHasChanged) {
        setlocaldonationamount(reduxdonation);
        // }
    }, [reduxdonation]);

    /**
     * test if the user is allready registered to the session
     *, 
     */
    useEffect(() => {
        if (orders.length > 0) {
            let myRegistration = orders.filter(order => order.customer.email === reduxcustomer.email && order.statut !== '-1')
            if (myRegistration.length > 0) {
                dispatch(setIsAllreadyRegistered(true));
                dispatch(setregistrationError({ code: 600, message: "Vous êtes déjà inscrit à ce stage" }));
                // return;
            } else {
                dispatch(setIsAllreadyRegistered(false));
            }
        } else
            dispatch(setIsAllreadyRegistered(false));
        //}
    }, [orders]);

    /**
     * If isAllreadyRegistrered change and === true afficher message
     */
    useEffect(() => {
        if (isAllreadyRegistrered === true) {
            confirm({
                description: "Vous êtes déjà inscrit à ce stage",
                title: t('validation.titleerror'),
                cancellationText: "",
                confirmationButtonProps: { autoFocus: true }
            })
            dispatch(setregistrationError(""));
        }
    }, [isAllreadyRegistrered]);

    /* useEffect(() => {
        if (productFullregistered.length > 0) {
            if (registration.product) {
                let max = registration.product.array_options.options_sta_nbmax;
                // if registration.product
                if (productFullregistered.length >= max)
                    dispatch(setIsSessionFull(true));
                else
                    dispatch(setIsSessionFull(false));
            }
        }
    }, [productFullregistered]);
 */
    function submitForm(event) {
        event.preventDefault();


        try {
            // *** Check the registration inputs
            validateRegistration(reduxregistration);
            // ***  check the user inputs
            validateIdentity(reduxcustomer);
            // ***  Calculate configuration
            dispatch(validateGlobalRegistration());
        }
        catch (error) {
            confirm({
                description: error.message,
                title: t('validation.titleerror'),
                cancellationText: "",
                confirmationButtonProps: { autoFocus: true }
            })
        }
    }


    /**
     * Sync function which validates identity
     * @param {*} customer 
     * @returns true if no error otherwise throws an error
     */
    function validateIdentity(customer) {

        // *** Check name
        if (!customer.civility) {
            throw Error(t('validation.civility'))
        }

        if (!customer.lastname) {
            throw Error(t('validation.lastname'))
        }

        if (!customer.firstname) {
            throw Error(t('validation.firstname'))
        }

        if (!customer.email) {
            throw Error(t('validation.email'))
        }
        if (!customer.emailbis) {
            throw Error(t('validation.emailbis'))
        }
        if (!(customer.emailbis === customer.email)) {
            throw Error(t('validation.emailbisok'))
        }
        if (customer.email.length > 0) {
            const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(customer.email)) {
                throw Error(t('validation.emailvalide'))
            }
        }
        if (customer.price_level === null) {
            throw Error(t('validation.incomelevel'))
        }

        return true;
    }


    /**
     * Sync function that 1) check the inputs 2) returns order lines from registration
     * @param {*} registration 
     * @returns orderlines or throws an error
     */
    function validateRegistration(registration) {
        if (!registration || !registration.product) {
            throw Error(t('validation.choosesession'));
        }

        if (!registration.tabBusyParticipation.includes(true)) {
            throw Error(t('validation.chooseparticipation'));
        }

        if (registration.tabBusyHosting && registration.tabBusyHosting.includes(true) && !registration.hostingType) {
            throw Error(t('validation.choosehosting'))
        }

        if (registration.tabBusyHosting[0] === true && registration.tabBusyHosting[1] !== true) {
            throw Error(t('validation.chooseinbeforehosting'))
        }
        if (registration.tabBusyHosting[registration.tabBusyHosting.length - 2] === true && registration.tabBusyHosting[1] !== true) {
            throw Error(t('validation.chooseinafterhosting'))
        }

        if ((registration.tabBusyBreakfast.includes(true) || registration.tabBusyLunch.includes(true) || registration.tabBusyDiner.includes(true))
            && !registration.mealType) {
            throw Error(t('validation.choosemealtype'))
        }
        // }
        return true;
    }

    function setlocdonationAmount(value) {

        if ((!isNaN(value) && parseFloat(value) > 0) || value === "") {
            // registration.donationamount = value;
            setlocaldonationamount(value);
            dispatch(setDonationAmount(value));
            dispatch(setReduxinputsChanged(true));
        } else {
            console.log("Valeur non prise : " + value);
            dispatch(setDonationAmount(reduxdonation));
        }
    }


    return (
        <div>
            <Card style={{ margin: "10px" }} >
                <CardHeader title={t('validation.blocktitle')} subheader="" style={{ backgroundColor: "#DFDFDB" }}>
                </CardHeader>

                <CardContent>
                    {/* /** Translations   */}
                    {displaydonation !== '1' &&
                        <Grid container style={{ marginTop: 0, marginBottom: 10, backgroundColor: "#FFFEFF" }}>
                            <Grid spacing={2} item md={12} xs={12}>
                                <Paper style={{ marginTop: "5px", padding: "10px" }}>
                                    <Grid container spacing={1} style={{ marginTop: "0px", paddingTop: "0px" }}>
                                        <Grid item md={8} xs={12} style={{ texAlign: "left" }}>
                                            <Typography align='left' >
                                                <b>{t('validation.donationtitle')} </b><br />
                                                {t('validation.donationtext')}</Typography>
                                        </Grid>
                                        <Grid item md={4} xs={12} >
                                            {/* <Typography>{t('validation.donationamount')}</Typography> */}
                                            <InputLabel htmlFor="standard-adornment-amount" style={{ marginTop: 20 }} >{t('validation.donationamount')}</InputLabel>
                                            <Input
                                                id="standard-adornment-amount"
                                                value={localdonationamount}
                                                onChange={(event) => {
                                                    setlocdonationAmount(event.target.value);
                                                }}
                                                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                            />

                                        </Grid>
                                        {/* donation {reduxdonation && reduxdonation} - {localdonationamount} */}
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>}
                    <Paper style={{ marginTop: "10px", padding: "10px" }}>
                        <Typography align='left' >
                            <b>{t('validation.button')} </b><br />
                        </Typography>
                        {/* //  */}
                        {!isRegistrationValidated && <Typography align="left"> {t('validation.pleaseclick')}</Typography>}
                        {isRegistrationValidated &&
                            <React.Fragment>
                                <Typography align="left">
                                    {t('validation.registrationfullamount')} {new Intl.NumberFormat("fr-FR", {
                                        style: "currency",
                                        currency: "EUR",
                                    }).format(loccomputedOrderlines.reduce((a, b) => a + parseFloat(b.total_ttc), 0))
                                    }
                                </Typography>
                                {!withoutdeposit === true &&
                                    <Typography align="left">
                                        {t('validation.registrationdepositamount')} {new Intl.NumberFormat("fr-FR", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                            style: "currency",
                                            currency: "EUR",
                                        }).format(loccomputedOrderlines.reduce((a, b) => a + parseFloat(b.total_ttc), 0) * getConfigurationdepositpercent())}.
                                    </Typography>}
                            </React.Fragment>}

                        <Typography className={classes.secondaryHeading}></Typography>
                        <Grid spacing={2} item md={12} xs={12} container>
                            <Grid item md={12} xs={12}>
                                <Button onClick={submitForm} color="primary" style={{ marginTop: "20px" }} variant="outlined">{t('validation.button')}</Button>
                            </Grid>
                        </Grid>
                        <OrderContent />
                        {isRegistrationValidated && <Box ><Typography align="left" variant="caption">{t('validation.registrationok')}
                        </Typography>
                        </Box>}
                    </Paper>
                </CardContent>
            </Card>
        </div>
    );
}

