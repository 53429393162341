import { store } from "../../App"
import {
  CONST_APIURLKUSALA,
} from "../../Constants.js";
import axios from "axios";
import { getUserToken } from "../Login/LoginActions";
import { getConfigurationdepositpercent, getConfigurationValue } from "../GlobalLists/GlobalListsActions"
import { getProductFromId } from "../CommonProduct/CommonProductActions"
import { computeRegistrationv2, computeChosenCourse } from "./OrderActions";
import { getProductFullRegistered, getProduct } from "./ProductActions";
import { getOpenMealsForADayRange } from "./RestaurantActions"
import moment from 'moment'

export const SET_REDUXCUSTOMER_SUCCESS = "SET_REDUXCUSTOMER_SUCCESS";
export const setReduxCustomer = (loccustomer) => ({
  type: SET_REDUXCUSTOMER_SUCCESS,
  payload: { loccustomer },
});

export const SET_REGISTRATION_SUCCESS = "SET_REGISTRATION_SUCCESS";
export const setReduxRegistration = (registration) => ({
  type: SET_REGISTRATION_SUCCESS,
  payload: { registration },
});

export const SET_ORDERLINES_SUCCESS = "SET_ORDERLINES_SUCCESS";
export const setReduxComputedOrderLines = (computedOrderlines) => ({
  type: SET_ORDERLINES_SUCCESS,
  payload: { computedOrderlines },
});


export const SET_REDUXPAYMENTMODE_SUCCESS = "SET_REDUXPAYMENTMODE_SUCCESS";
export const setReduxPaymentmode = (payment) => ({
  type: SET_REDUXPAYMENTMODE_SUCCESS,
  payload: { payment },
});

//*** The chosen course is full */
/* export const SET_SESSIONISFULL_SUCCESS = "SET_SESSIONISFULL_SUCCESS";
export const setIsSessionFull = (isSessionFull) => ({
  type: SET_SESSIONISFULL_SUCCESS,
  payload: { isSessionFull },
}); */

// *** The user is allready registered to the chosen couurse
export const SET_ALLREADYREGISTERED_SUCCESS = "SET_ALLREADYREGISTERED_SUCCESS";
export const setIsAllreadyRegistered = (isAllreadyRegistrered) => ({
  type: SET_ALLREADYREGISTERED_SUCCESS,
  payload: { isAllreadyRegistrered },
});

//*** The registration has been validated with no error */
export const SET_ISREGISTRATIONVALIDATED_SUCCESS = "SET_ISREGISTRATIONVALIDATED_SUCCESS";
export const setIsRegistrationValidated = (isRegistrationValidated) => ({
  type: SET_ISREGISTRATIONVALIDATED_SUCCESS,
  payload: { isRegistrationValidated },
});

export const SET_REGISTRATIONERROR = "SET_REGISTRATIONERROR";
export const setregistrationError = (registrationError) => ({
  type: SET_REGISTRATIONERROR,
  payload: { registrationError },
});

// *** The inputs (name, address) have changed
export const SET_INPUTSCHANGED = "SET_INPUTSCHANGED";
export const setReduxinputsChanged = (inputsChanged) => ({
  type: SET_INPUTSCHANGED,
  payload: { inputsChanged },
});

// *** Redux is loading a new course (product, meals booking, session booking)
export const SET_REDUXLOADING = "SET_REDUXLOADING";
export const setReduxLoading = (status) => ({
  type: SET_REDUXLOADING,
  payload: { status },
});

// *** Redux is loading a new course (product, meals booking, session booking)
export const SET_COURSEHASCHANGED = "SET_COURSEHASCHANGED";
export const setCourseHasChanged = (courseHasChanged) => ({
  type: SET_COURSEHASCHANGED,
  payload: { courseHasChanged },
});

// *** Redux is loading a new course (product, meals booking, session booking)
export const SET_ORDERTOTALAMOUNT = "SET_ORDERTOTALAMOUNT";
export const setOrderTotalAmount = (orderTotalAmount) => ({
  type: SET_ORDERTOTALAMOUNT,
  payload: { orderTotalAmount },
});


// *** Set the amount of the donation
export const SET_DONATIONAMOUNT = "SET_DONATIONAMOUNT";
export const setDonationAmount = (donationamount) => ({
  type: SET_DONATIONAMOUNT,
  payload: { donationamount },
});


/**
 * Change the current course, update the registration structure
 * @param {*} productTemp 
 * @param {*} checkDefaultState 
 */
export function courseChange(productTemp, checkDefaultState) {
  return (dispatch) => {
    dispatch(setReduxRegistration({}));
    dispatch(getProduct(productTemp.id));
    dispatch(setCourseHasChanged(false));
    dispatch(setIsAllreadyRegistered(false));
    dispatch(setIsRegistrationValidated(false));
    // dispatch(setDonationAmount(0));
    dispatch(getOpenMealsForADayRange(productTemp.array_options.options_sta_datedebut, productTemp.array_options.options_sta_datefin));

    let tempregistration = computeChosenCourse(productTemp, checkDefaultState);
    dispatch(setReduxRegistration(tempregistration));
    dispatch(setReduxinputsChanged(true));
    dispatch(setCourseHasChanged(true));
  }
}

/**
 * Sync function that 1) check the inputs 2) returns order lines from registration
 * @param {*} registration 
 * @returns orderlines or throws an error
 */
/* export function computeConfigRegistration(registration) {
  if (!registration || !registration.product) {
    throw Error("Veuillez choisir un stage");
  }

  if (!registration.tabBusyParticipation.includes(true)) {
    throw Error("Veuillez sélectionner au moins un jour de participation au stage");
  }

  if (registration.tabBusyHosting && registration.tabBusyHosting.includes(true) && !registration.hostingType) {
    throw Error("Si vous demandez des hébergements, il faut choisir un type d'hébergement")
  }

  if (registration.tabBusyHosting[0] === true && registration.tabBusyHosting[1] !== true) {
    throw Error("Si vous souhaitez être hébergé le jour d'avant, il faut demander à être hébergé durant le stage")
  }
  if (registration.tabBusyHosting[registration.tabBusyHosting.length - 2] === true && registration.tabBusyHosting[1] !== true) {
    throw Error("Si vous souhaitez être hébergé le dernier jour du stage, il faut demander à être hébergé durant le stage")
  }

  if ((registration.tabBusyBreakfast.includes(true) || registration.tabBusyLunch.includes(true) || registration.tabBusyDiner.includes(true))
    && !registration.mealType) {
    throw Error("Si vous demandez des repas, il faut choisir un type de repas")
  }

  let computedOrderlines = computeRegistrationv2(registration);
  return computedOrderlines;

} */

/**
 * Put values in order lines 
 * @param {*} orderLines 
 * @returns 
 */
export function computeOrderLines(orderLines, customer) {

  let registration = store.getState().registrationReducer.registration;
  let customerPriceLevel = parseInt(store.getState().registrationReducer.loccustomer.price_level.code);
  let donation = store.getState().registrationReducer.donationamount;

  let product = "";
  let newOrderLines = [...orderLines];
  newOrderLines.map((orderLine, index) => {
    if (typeof orderLine.fk_product === "string")
      product = getProductFromId(orderLine.fk_product);

    else
      product = orderLine.fk_product;
    let price = product.multiprices_ttc[customerPriceLevel];
    orderLine.subprice = price;
    orderLine.total_ttc = orderLine.qty * price;
    orderLine.label = product.label
    orderLine.ref = product.ref
  })

  // *** Add subscription if requested
  // if (Object.keys(customer.subsproductId).length > 0) {
  if (customer.subsproductId !== null) {
    // *** Define the start and end dates
    let startDate = new Date(moment(new Date()).format('YYYY-MM-DD'));
    let end = new Date(startDate);
    end.setYear(end.getFullYear() + 1)

    newOrderLines.push(
      {

        "array_options": {
          "options_lin_datedebut": new Date(startDate) / 1000,
          "options_lin_datefin": new Date(end) / 1000,
        },
        fk_product: getConfigurationValue('Don'),
        label: customer.subsproductId.label,
        qty: 1,
        ref: customer.subsproductId.ref,
        subprice: customer.subsproductId.multiprices_ttc[customerPriceLevel],
        total_ttc: customer.subsproductId.multiprices_ttc[customerPriceLevel]
      }
    )
  }

  // *** Add Donation if requested
  if (donation !== null && donation !== "" && !isNaN(donation) && parseFloat(donation) > 0) {
    // *** Define the start and end dates
    let startDate = new Date(moment(new Date()).format('YYYY-MM-DD'));
    let end = new Date(moment(new Date()).format('YYYY-MM-DD'));;
    // end.setYear(end.getFullYear() + 1);

    let productDon = getProductFromId(getConfigurationValue('Don'));
    if (productDon == null)
      throw Error("Configuration error, donation code");

    // let var1 = getConfigurationValue('Don');
    newOrderLines.push(
      {

        "array_options": {
          "options_lin_datedebut": new Date(startDate) / 1000,
          "options_lin_datefin": new Date(end) / 1000,
        },
        fk_product: getConfigurationValue('Don'),
        label: productDon.label,
        qty: 1,
        // ref: customer.subsproductId.ref,
        subprice: donation,
        total_ttc: donation
      }
    )
  }

  return newOrderLines;
}

/****************************************************************************************** */
/**
 * Main validation function : in charge of 
 * Validating inputs, computing the registration, compute the order ttc
 * Check the existence of the customer in Dolibarr
 * Send the order to Dolibarr 
 * @returns 
 */
export function validateGlobalRegistration() {
  return (dispatch) => {

    const loccustomer = store.getState().registrationReducer.loccustomer;
    const registration = store.getState().registrationReducer.registration;

    try {
      dispatch(setIsRegistrationValidated(false));
      dispatch(setIsAllreadyRegistered(false));
      dispatch(setregistrationError(""));

      // *** manage registration and get orders lines
      //  let computedOrderlines = computeConfigRegistration(registration);

      let computedOrderlines = computeRegistrationv2(registration);
      dispatch(setReduxRegistration(registration));

      // *** Check identity
      //  validateIdentity(loccustomer);

      // *** Manage orders lines, put prices
      let valorisedOrderLines = computeOrderLines(computedOrderlines, loccustomer);
      dispatch(setReduxComputedOrderLines(valorisedOrderLines));

      // *** Compute order total price
      const loccomputedOrderlines = store.getState().registrationReducer.computedOrderlines;
      dispatch(setOrderTotalAmount(loccomputedOrderlines.reduce((a, b) => a + parseFloat(b.total_ttc), 0)));

      dispatch(getProductFullRegistered(registration.product.id));
      dispatch(setReduxinputsChanged(false));

    } catch (Error) {
      dispatch(setregistrationError(Error));
    }
    return true;
  }
}

/****************************************************************************************** */
export function sendToDolibarr(captchaValue) {
  return (dispatch) => {
    // const customerDoli = store.getState().customerReducer.customer
    const loccustomer = store.getState().registrationReducer.loccustomer;
    const registration = store.getState().registrationReducer.registration;
    const loccomputedOrderlines = store.getState().registrationReducer.computedOrderlines;
    const payment = store.getState().registrationReducer.payment;


    dispatch(validateGlobalRegistration());
    dispatch(sendWebOrder(loccustomer, registration, loccomputedOrderlines, payment, captchaValue));

  }
}

/****************************************************************************************** */
export function sendWebOrder(loccustomer, registration, loccomputedOrderlines, payment, captchaValue) {

  return (dispatch) => {
    dispatch(sendWebOrderBegin());

    let customer = loccustomer;
    customer.price_level = customer.price_level.rowid;

    let acompte = Math.round(loccomputedOrderlines.reduce((a, b) => a + parseFloat(b.total_ttc), 0) * getConfigurationdepositpercent());
    let total = loccomputedOrderlines.reduce((a, b) => a + parseFloat(b.total_ttc), 0);

    if (payment.paymentMode.includes("acompte"))
      payment.subprice = Math.round(loccomputedOrderlines.reduce((a, b) => a + parseFloat(b.total_ttc), 0) * getConfigurationdepositpercent());
    else
      payment.subprice = loccomputedOrderlines.reduce((a, b) => a + parseFloat(b.total_ttc), 0);

    payment.captchaValue = captchaValue;
    return axios
      .post(
        CONST_APIURLKUSALA +
        "dklaccueil/weborder" +
        "?DOLAPIKEY=" +
        getUserToken(),
        {
          globalRegistration: {
            customer: customer,
            registration: registration,
            orderlines: loccomputedOrderlines,
            payment: payment
          }
        }
      )
      .then((json) => {
        dispatch(sendWebOrderSuccess(json.data));
        console.log("sendWebOrderSuccess : " + json.data);
        // let total_ttc = loccomputedOrderlines.reduce((a, b) => a + parseFloat(b.total_ttc), 0);
        //*** paiement par carte */
        /* if (payment.paymentMode.includes('carte')) {
          let invoiceId = json.data.invoiceId;
          let url = window.location.protocol +
            "//" +
            window.location.hostname +
            "/dolibarr/custom/helloassopay/public/start.php?ref=" + invoiceId;
          window.location.assign(url);


        } */
        //  let orderdata = JSON.parse(json.data);

        /*           axios
                    .post(
                      CONST_APIURLKUSALA +
                      "payplug/createpaiement" +
                      "?DOLAPIKEY=" +
                      getUserToken(),
                      {
                        "invoice_id": orderdata.invoiceId,
                        "amount": payment.subprice,
                        "customer_id": orderdata.socid,
                        "title": loccustomer.civility,
                        "firstname": loccustomer.firstname,
                        "lastname": loccustomer.lastname,
                        "email": loccustomer.email,
                        "sent_by": "VAD"
                      }
                    )
                    .then((json) => {
                      window.location = json.data;
                      console.log("paiement envoyé")
                    })
                    .catch((error) => {
                      console.log("sendWebOrderFailure");
                      // *** an 404 error is sent when Dolibarr didn't find invoices
                      if (error.response) {
                        if (error.response.status === 404) {
                          dispatch(sendWebOrderFailure(error.response.data.error));
                        } else {
                          dispatch(sendWebOrderFailure(error.response.data.error));
                        }
                      } else {
                        // *** It's an API error
                        dispatch(sendWebOrderFailure(error));
                      }
                    }); */
        // }

      })

      .catch((error) => {
        console.log("sendWebOrderFailure");
        // *** an 404 error is sent when Dolibarr didn't find invoices
        if (error.response) {
          if (error.response.status === 404) {
            dispatch(sendWebOrderFailure(error.response.data.error));
          } else {
            dispatch(sendWebOrderFailure(error.response.data.error));
          }
        } else {
          // *** It's an API error
          dispatch(sendWebOrderFailure(error));
        }
      });
  }
}



export const SEND_WEBORDER_BEGIN = "SEND_WEBORDER_BEGIN";
export const SEND_WEBORDER_SUCCESS = "SEND_WEBORDER_SUCCESS";
export const SEND_WEBORDER_FAILURE = "SEND_WEBORDER_FAILURE";

export const sendWebOrderBegin = () => ({
  type: SEND_WEBORDER_BEGIN,
});

export const sendWebOrderSuccess = (webOrderResult) => ({
  type: SEND_WEBORDER_SUCCESS,
  payload: { webOrderResult },
});

export const sendWebOrderFailure = (error) => ({
  type: SEND_WEBORDER_FAILURE,
  payload: { error },
});

/*
export function chosenCourseChanged() {
  return (dispatch) => {

    //  dispatch(getMaxMealsForADayRange());

  }
}
 */

// export const CHOSENCOURSECHANGED_BEGIN = "CHOSENCOURSECHANGED_BEGIN";
// export const CHOSENCOURSECHANGED_SUCCESS = "CHOSENCOURSECHANGED_SUCCESS";
// export const CHOSENCOURSECHANGED_FAILURE = "CHOSENCOURSECHANGED_FAILURE";

// export const chosenCourseChangedBegin = () => ({
//   type: CHOSENCOURSECHANGED_BEGIN,
// });

// export const chosenCourseChangedSuccess = () => ({
//   type: CHOSENCOURSECHANGED_SUCCESS,
//   // payload: { webOrderResult },
// });

// export const chosenCourseChangedFailure = () => ({
//   type: CHOSENCOURSECHANGED_FAILURE,
//   ///  payload: { error },
// });
