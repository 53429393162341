import React from "react";
import { useTranslation } from "react-i18next";

export default function LanguageSelector(props) {
    const { t, i18n } = useTranslation();

    const changeLanguage = (event) => {
        i18n.changeLanguage(event.target.value);
    };
    return (

        <div onChange={changeLanguage}>
            {i18n.language === 'en' ? <input type="radio" value="en" name="language" /> : <input type="radio" value="en" name="language" />}  English
            {i18n.language === 'fr' ? <input type="radio" value="fr" name="language" /> : <input type="radio" value="fr" name="language" />} French
            {i18n.language === 'de' ? <input type="radio" value="de" name="language" /> : <input type="radio" value="de" name="language" />}  German
            {i18n.language === 'sp' ? <input type="radio" value="sp" name="language" /> : <input type="radio" value="sp" name="language" />}  Spanish
            {/* - active language : {i18n.language} */}
        </div>

    );
}
