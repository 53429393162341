import {
  CONST_APIURLKUSALA,
  //  CONST_DOLISERVERKEYKUSALA,
} from "../../Constants.js";
import { getUserToken } from "../Login/LoginActions";
import axios from "axios";
// import { store } from "../../../App.js";
// import { getRestaurantProducts } from "../CommonProduct/CommonProductActions"
// import { getMealsResourceProducts } from "../CommonProduct/CommonProductActions"
import { getConfigurationValue } from "../GlobalLists/GlobalListsActions";
import moment from 'moment'

/**
 * Get the meal max for a range of dates
 * this function use a dklaccueil API because Dolibarr have not this function in the API
 * The data are taken from the agenda of dolibarr
 * @param {*} requesteStartdDay
 * @param {*} requestedEndDay 
 */
export function getOpenMealsForADayRange(requesteStartdDayOrigin, requestedEndDayOrigin) {
  return (dispatch) => {
    console.log("getOpenMealsForADayRange  ");
    dispatch(getOpenMealsForADayRangeBegin());

    let requesteStartdDay = moment(requesteStartdDayOrigin * 1000).add(-1, 'days').format("YYYY-MM-DD");
    let requestedEndDay = moment(requestedEndDayOrigin * 1000).add(+1, 'days').format("YYYY-MM-DD");
    return axios
      .get(
        CONST_APIURLKUSALA +
        "agendaevents" +
        "?DOLAPIKEY=" +
        getUserToken() +
        "&sqlfilters=" +
        "(((t.datep:>=:'" + requesteStartdDay + "') and (t.datep2:<=:'" + requestedEndDay + "'))" +
        " OR ((t.datep:>=:'" + requesteStartdDay + "') and (t.datep:<=:'" + requestedEndDay + "'))" +
        " OR ((t.datep2:>=:'" + requesteStartdDay + "') and (t.datep2:<=:'" + requestedEndDay + "'))" +
        " OR ((t.datep:<=:'" + requesteStartdDay + "') and (t.datep2:>=:'" + requestedEndDay + "')))" +
        " and (CODE:=:'AC_ZOPAMEAL')"
      ) // 
      .then((json) => {
        console.log(getOpenMealsForADayRangeSuccess);

        let dateDebut = new Date(requesteStartdDay + " 00:00");

        let maxMealsTab = buildEmptyArray(requesteStartdDay, requestedEndDay);
        json.data.forEach(function (item) {

          var dateDebutItem = new Date(item.datep * 1000);
          var dateFinItem = new Date(item.datef * 1000);
          var startOffsetDays = Math.round((dateDebutItem - dateDebut) / 86400000) + 1; // The offset between the start of the line and the start of the analysed period
          var nbJours = ((dateFinItem - dateDebutItem) / 86400000) + 1; // The # days of the line

          // *** Search for the index in the result table and set the closed meals
          for (let i = 0; i <= nbJours - 1; i++) {
            let jsonMax = JSON.parse(item.note_private.replace(/&quot;/g, '"'));
            if (jsonMax.P === 1)
              maxMealsTab[1][i + startOffsetDays] = jsonMax.P;
            if (jsonMax.M === 1)
              maxMealsTab[2][i + startOffsetDays] = jsonMax.M;
            if (jsonMax.S === 1)
              maxMealsTab[3][i + startOffsetDays] = jsonMax.S;
          }
        });

        // ** Add the day before and day after configuration, 
        maxMealsTab = addDaysConfiguration(maxMealsTab);

        dispatch(getOpenMealsForADayRangeSuccess(maxMealsTab));
      })
      .catch((error) => {
        console.log(getOpenMealsForADayRangeFailure);

        // *** 
        if (error.response) {
          // *** It's a Dolibarr error
          if (error.response && error.response.status === 404) {
            // ** When no closed meal, we build an array with the daybefore and day after configuration 
            let maxMealsTab = buildEmptyArray(requesteStartdDay, requestedEndDay);
            maxMealsTab = addDaysConfiguration(maxMealsTab);
            dispatch(getOpenMealsForADayRangeSuccess(maxMealsTab));

          }
          else
            dispatch(getOpenMealsForADayRangeFailure(error.response.data.error));
        } else {
          // *** It's an API error
          dispatch(getOpenMealsForADayRangeFailure(error));
        }
      });
  };
}

export const GET_OPENMEALSFORADAYRANGE_INIT = "GET_OPENMEALSFORADAYRANGE_INIT";
export const GET_OPENMEALSFORADAYRANGE_BEGIN = "GET_OPENMEALSFORADAYRANGE_BEGIN";
export const GET_OPENMEALSFORADAYRANGE_FAILURE = "GET_OPENMEALSFORADAYRANGE_FAILURE";
export const GET_OPENMEALSFORADAYRANGE_SUCCESS = "GET_OPENMEALSFORADAYRANGE_SUCCESS";

export const getOpenMealsForADayRangeInit = () => ({
  type: GET_OPENMEALSFORADAYRANGE_BEGIN,
});

export const getOpenMealsForADayRangeBegin = () => ({
  type: GET_OPENMEALSFORADAYRANGE_BEGIN,
});

export const getOpenMealsForADayRangeSuccess = (openMealsForADayRangeValues) => ({
  type: GET_OPENMEALSFORADAYRANGE_SUCCESS,
  payload: { openMealsForADayRangeValues },
});

export const getOpenMealsForADayRangeFailure = (newerror) => ({
  type: GET_OPENMEALSFORADAYRANGE_FAILURE,
  payload: { newerror },
});

/**
 * Build the array containing the closed meals for a period
 * @param {Date} requesteStartdDay 
 * @param {Date} requestedEndDay 
 * @returns Array
 */
export function buildEmptyArray(requesteStartdDay, requestedEndDay) {
  let dateDebut = new Date(requesteStartdDay + " 00:00");
  let dateFin = new Date(requestedEndDay + " 00:00");
  let nbJour = Math.round((dateFin - dateDebut) / 86400000) + 1;
  let maxMealsTab = [];

  let tabTitleLine = [];
  tabTitleLine.push("Title");
  for (let ligne = 0; ligne < nbJour; ++ligne) {
    var datedebutemp = new Date(dateDebut);
    var tempdateline = dateDebut.getDate() + ligne;
    var tempDate = new Date(datedebutemp.setDate(tempdateline));
    tabTitleLine.push(tempDate.getDate() + "/" + (tempDate.getMonth() + 1));
  }
  maxMealsTab.push(tabTitleLine);

  let tabLabels = [];
  tabLabels.push("Matin");
  tabLabels.push("Midi");
  tabLabels.push("Soir");
  for (let ligne = 0; ligne < 3; ++ligne) {
    let tabMaxMealsDataLine = new Array(nbJour + 1).fill(0);
    tabMaxMealsDataLine[0] = tabLabels[ligne];
    maxMealsTab.push(tabMaxMealsDataLine)
  }

  return maxMealsTab;
}

/**
 * Add to the open meals array the daybefore and dayafter configuration
 * @param {Array} maxMealsTab 
 */
export function addDaysConfiguration(maxMealsTab) {

  if (getConfigurationValue('daybefore')) {
    let dayBefore = getConfigurationValue('daybefore');
    if (maxMealsTab[1][1] === 0)
      if (dayBefore.P !== undefined)
        maxMealsTab[1][1] = dayBefore.P;
    if (maxMealsTab[2][1] === 0)
      if (dayBefore.M !== undefined)
        maxMealsTab[2][1] = dayBefore.M;
    if (maxMealsTab[3][1] === 0)
      if (!dayBefore.S !== undefined)
        maxMealsTab[3][1] = dayBefore.S;
  }

  if (getConfigurationValue('dayafter')) {
    let dayAfter = getConfigurationValue('dayafter');
    if (maxMealsTab[1][maxMealsTab[1].length - 1] === 0)
      if (dayAfter.P !== undefined)
        maxMealsTab[1][maxMealsTab[1].length - 1] = dayAfter.P;

    if (maxMealsTab[2][maxMealsTab[2].length - 1] === 0)
      if (dayAfter.M !== undefined)
        maxMealsTab[2][maxMealsTab[2].length - 1] = dayAfter.M;

    if (maxMealsTab[3][maxMealsTab[3].length - 1] === 0)
      if (!dayAfter.S !== undefined)
        maxMealsTab[3][maxMealsTab[3].length - 1] = dayAfter.S;
  }

  return maxMealsTab;

}