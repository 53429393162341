import {
  CONST_APIURLKUSALA,
  // CONST_DOLISERVERKEYKUSALA,
} from "../../Constants.js";
import axios from "axios";
import { store } from "../../App"
import { getUserToken } from "../Login/LoginActions";


// **********************
/**
 * Get Dolibarr status
 */
export function getDolibarrStatus() {
  return (dispatch) => {
    console.log("getDolibarrStatus");
    dispatch(getDolibarrStatusBegin());
    return axios.get(
      CONST_APIURLKUSALA +
      "status" +
      "?DOLAPIKEY=" + getUserToken()
    )
      .then((json) => {
        console.log(" getDolibarrStatusSuccess : ");
        dispatch(getDolibarrStatusSuccess(json.data));
      })
      .catch((error) => {
        console.log(" getDolibarrStatusFailure");
        // *** an 404 error is sent when Dolibarr didn't find invoices
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(getDolibarrStatusFailure({ code: "600", message: "Veuillez vous authentifier" }));
          } else {
            dispatch(getDolibarrStatusFailure({ code: error.response.status, message: error.response.statusText }));
          }
        } else {
          // *** It's an API error
          dispatch(getDolibarrStatusFailure({ code: "600", message: error.message }));
        }
      });
  };
}

export const GET_DOLIBARRSTATUS_BEGIN =
  "GET_DOLIBARRSTATUS_BEGIN";
export const GET_DOLIBARRSTATUS_SUCCESS =
  "GET_DOLIBARRSTATUS_SUCCESS";
export const GET_DOLIBARRSTATUS_FAILURE =
  "GET_DOLIBARRSTATUS_FAILURE";

export const getDolibarrStatusBegin = () => ({
  type: GET_DOLIBARRSTATUS_BEGIN,
});

export const getDolibarrStatusSuccess = (dolibarrstatus) => ({
  type: GET_DOLIBARRSTATUS_SUCCESS,
  payload: { dolibarrstatus },
});

export const getDolibarrStatusFailure = (error) => ({
  type: GET_DOLIBARRSTATUS_FAILURE,
  payload: { error },
});


//*** Application initialisation**************************** */
export function getConfiguration() {
  return (dispatch) => {
    // let configurationFile = "";

    if (store.getState().globalListsReducer.configuration !== "")
      return;

    fetch('configuration.json', { cache: "no-store" })
      .then((r) => r.json())
      .then((data) => {
        dispatch(getConfigurationSuccess(data));
      })
      .catch((error) => {
        dispatch(getConfigurationFailure(error));
      })
  }
}

export function getConfigurationdepositpercent() {
  return store.getState().globalListsReducer.configuration.depositpercent;
}

export function getConfigurationValue(valueName) {
  let config = store.getState().globalListsReducer.configuration;
  return config[valueName];

}

export function getConfigurationSuccess(configuration) {
  return (dispatch) => {
    dispatch({
      type: GET_CONFIGURATION_SUCCESS,
      payload: { configuration },
    });
  }
}
export const GET_CONFIGURATION_SUCCESS = "GET_CONFIGURATION_SUCCESS";

export function getConfigurationFailure(error) {
  return (dispatch) => {
    dispatch({
      type: GET_CONFIGURATION_FAILURE,
      payload: { error },
    });
  }
}
export const GET_CONFIGURATION_FAILURE = "GET_CONFIGURATION_FAILURE";

// **********************
// **** Customer civilities
export function getCustomerCivilitiesTable() {
  return (dispatch) => {
    dispatch(getCustomerCivilitiesTableBegin());

    if (store.getState().globalListsReducer.civilities.length > 0)
      return;

    return axios.get(
      CONST_APIURLKUSALA +
      "setup/dictionary/civilities?sortfield=code&sortorder=ASC&limit=100&active=1" +
      "&DOLAPIKEY=" +
      getUserToken()
    )
      .then((json) => {
        console.log(" getCustomerCivilitiesTableSuccess : ");
        dispatch(getCustomerCivilitiesTableSuccess(json.data));
      })
      .catch((error) => {
        console.log(" getCustomerFixedAmountDiscountsFailure");
        // *** an 404 error is sent when Dolibarr didn't find invoices
        if (error.response) {
          if (error.response.status === 404) {
            dispatch(getCustomerCivilitiesTableSuccess([]));
          } else {
            dispatch(getCustomerCivilitiesTableFailure(error.response.data.error));
          }
        } else {
          // *** It's an API error
          dispatch(getCustomerCivilitiesTableFailure(error));
        }
      });
  };
}

export const GET_CUSTOMERCIVILITIESTABLE_BEGIN =
  "GET_CUSTOMERCIVILITIESTABLE_BEGIN";
export const GET_CUSTOMERCIVILITIESTABLE_SUCCESS =
  "GET_CUSTOMERCIVILITIESTABLE_SUCCESS";
export const GET_CUSTOMERCIVILITIESTABLE_FAILURE =
  "GET_CUSTOMERCIVILITIESTABLE_FAILURE";

export const getCustomerCivilitiesTableBegin = () => ({
  type: GET_CUSTOMERCIVILITIESTABLE_BEGIN,
});

export const getCustomerCivilitiesTableSuccess = (
  civilities
) => ({
  type: GET_CUSTOMERCIVILITIESTABLE_SUCCESS,
  payload: { civilities },
});

export const getCustomerCivilitiesTableFailure = (error) => ({
  type: GET_CUSTOMERCIVILITIESTABLE_FAILURE,
  payload: { error },
});



export function getCustomerCivilityLabel(civility_id) {

  // let civilities = store.getState().globalListsReducer.civilities;
  let civilityLine = store.getState().globalListsReducer.civilities.filter(civility => civility.rowid === civility_id);

  if (civilityLine.length > 0)
    return civilityLine[0].code;
  else
    return null;


}
// **********************
// **** Customer publipostage choices
export function getPublipostageTable() {
  return (dispatch) => {
    dispatch(getPublipostageTableBegin());

    if (store.getState().globalListsReducer.publipostages.length > 0)
      return;

    return axios.get(
      CONST_APIURLKUSALA +
      "setup/extrafields?sqlfilters=(t.label:=:'Publipostage')" +
      "&DOLAPIKEY=" +
      getUserToken()
    )

      .then((json) => {
        console.log(" getPublipostageTableSuccess : ");
        dispatch(getPublipostageTableSuccess(Object.entries(json.data.societe.publipostage.param.options)));
      })

      .catch((error) => {
        console.log(" getPublipostageTableFailure");
        // *** an 404 error is sent when Dolibarr didn't find invoices
        if (error.response) {
          if (error.response.status === 404) {
            dispatch(getPublipostageTableSuccess([]));
          } else {
            dispatch(getPublipostageTableFailure(error.response.data.error));
          }
        } else {
          // *** It's an API error
          dispatch(getPublipostageTableFailure(error));
        }
      });
  };
}

export const GET_PUBLIPOSTAGETABLE_BEGIN = "GET_PUBLIPOSTAGETABLE_BEGIN";
export const GET_PUBLIPOSTAGETABLE_SUCCESS = "GET_PUBLIPOSTAGETABLE_SUCCESS";
export const GET_PUBLIPOSTAGETABLE_FAILURE = "GET_PUBLIPOSTAGETABLE_FAILURE";

export const getPublipostageTableBegin = () => ({
  type: GET_PUBLIPOSTAGETABLE_BEGIN,
});

export const getPublipostageTableSuccess = (publipostages) => ({
  type: GET_PUBLIPOSTAGETABLE_SUCCESS,
  payload: { publipostages },
});

export const getPublipostageTableFailure = (error) => ({
  type: GET_PUBLIPOSTAGETABLE_FAILURE,
  payload: { error },
});



// **********************
// **** Customer price level
export function getIncomesLevelTable(activeStatus) {
  return (dispatch) => {
    console.log("getIncomesLevelTable : ");
    dispatch(getIncomesLevelBegin());

    if (store.getState().globalListsReducer.incomelevels.length > 0)
      return;

    return axios.get(
      CONST_APIURLKUSALA +
      "dklaccueil/dictionary/incomeleveltypes?sortfield=code&sortorder=ASC&limit=100&active=1" +
      "&DOLAPIKEY=" +
      getUserToken() +
      "&sqlfilters=t.label like 'ZW%'"
    )
      .then((json) => {
        console.log(" getIncomesLevelSuccess : ");
        dispatch(getIncomesLevelSuccess(json.data));
      })
      .catch((error) => {
        console.log(" getIncomesLevelFailure");
        // *** an 404 error is sent when Dolibarr didn't find invoices
        if (error.response) {
          if (error.response.status === 404) {
            dispatch(getIncomesLevelSuccess([]));
          } else {
            dispatch(getIncomesLevelFailure(error.response.data.error));
          }
        } else {
          // *** It's an API error
          dispatch(getIncomesLevelFailure(error));
        }
      });
  };
}

export const GET_INCOMESLEVELTABLE_BEGIN = "GET_INCOMESLEVELTABLE_BEGIN";
export const GET_INCOMESLEVELTABLE_SUCCESS = "GET_INCOMESLEVELTABLE_SUCCESS";
export const GET_INCOMESLEVELTABLE_FAILURE = "GET_INCOMESLEVELTABLE_FAILURE";

export const getIncomesLevelBegin = () => ({
  type: GET_INCOMESLEVELTABLE_BEGIN,
});

export const getIncomesLevelSuccess = (incomelevels) => ({
  type: GET_INCOMESLEVELTABLE_SUCCESS,
  payload: { incomelevels },
});

export const getIncomesLevelFailure = (error) => ({
  type: GET_INCOMESLEVELTABLE_FAILURE,
  payload: { error },
});


//****************************************************** */
// *****************************************************************************************
// **** get payment types
export function getPaymentTypes() {
  return (dispatch) => {
    console.log("getPaymentTypesBegin");
    dispatch(getPaymentTypesBegin());

    if (store.getState().globalListsReducer.paymentTypes.length > 0)
      return;


    return axios.get(
      CONST_APIURLKUSALA +
      "setup/dictionary/payment_types?sortfield=code&sortorder=ASC&limit=100&active=1" +
      "&DOLAPIKEY=" +
      getUserToken(),
    )
      .then((json) => {
        console.log("getPaymentTypesSuccess");
        dispatch(getPaymentTypesSuccess(json.data));
      })
      .catch((error) => {
        console.log("getPaymentTypesFailure");
        // *** an 404 error is sent when Dolibarr didn't find invoices
        if (error.response) {
          if (error.response.status === 404) {
            dispatch(getPaymentTypesSuccess([]));
          } else {
            dispatch(getPaymentTypesFailure(error.response.data.error));
          }
        } else {
          // *** It's an API error
          dispatch(getPaymentTypesFailure(error));
        }
      });
  };
}

export const GET_PAYMENTTYPES_BEGIN = "GET_PAYMENTTYPES_BEGIN";
export const GET_PAYMENTTYPES_SUCCESS = "GET_PAYMENTTYPES_SUCCESS";
export const GET_PAYMENTTYPES_FAILURE = "GET_PAYMENTTYPES_FAILURE";

export const getPaymentTypesBegin = () => ({
  type: GET_PAYMENTTYPES_BEGIN,
});

export const getPaymentTypesSuccess = (paymentTypes) => ({
  type: GET_PAYMENTTYPES_SUCCESS,
  payload: { paymentTypes },
});

export const getPaymentTypesFailure = (error) => ({
  type: GET_PAYMENTTYPES_FAILURE,
  payload: { error },
});


// **********************
// **** Countries
export function getCountriesTable() {
  return (dispatch) => {
    dispatch(getCountriesTableBegin());

    if (store.getState().globalListsReducer.countries.length > 0)
      return;

    return axios.get(
      CONST_APIURLKUSALA +
      "setup/dictionary/countries?sortfield=label&sortorder=ASC&active=1" +
      "&DOLAPIKEY=" +
      getUserToken(),
      {
        params: {
          sortfield: "label",
          sortorder: "ASC",
          limit: "300"
        },
      }
    )
      .then((json) => {
        console.log(" getCountriesTablesuccess : ");
        let newcountries = json.data.map(country => country != false && country.label.length > 0 &&
          { "id": country.id, "label": country.label })
        dispatch(getCountriesTableSuccess(newcountries));
      })
      .catch((error) => {
        console.log(" getCountriesTableFailure");
        // *** an 404 error is sent when Dolibarr didn't find invoices
        if (error.response) {
          if (error.response.status === 404) {
            dispatch(getCountriesTableSuccess([]));
          } else {
            dispatch(getCountriesTableFailure(error.response.data.error));
          }
        } else {
          // *** It's an API error
          dispatch(getCountriesTableFailure(error));
        }
      });
  };
}

export const GET_COUNTRIESTABLE_BEGIN =
  "GET_COUNTRIESTABLE_BEGIN";
export const GET_COUNTRIESTABLE_SUCCESS =
  "GET_COUNTRIESTABLE_SUCCESS";
export const GET_COUNTRIESTABLE_FAILURE =
  "GET_COUNTRIESTABLE_FAILURE";

export const getCountriesTableBegin = () => ({
  type: GET_COUNTRIESTABLE_BEGIN,
});

export const getCountriesTableSuccess = (countries) => ({
  type: GET_COUNTRIESTABLE_SUCCESS,
  payload: { countries },
});

export const getCountriesTableFailure = (error) => ({
  type: GET_COUNTRIESTABLE_FAILURE,
  payload: { error },
});

/* 

export function getUserLoginFromId(id) {
  let selectedUSer = store.getState().globalListsReducer.users.filter(user => user.id === id);
  if (selectedUSer.length === 0)
    return "Inconnu";
  else
    return selectedUSer[0].login;
}

export function getUsersListWithNullValue() {
  let users = store.getState().globalListsReducer.users.slice();
  users.push({ id: "0", login: "Pas de sélection" })
  return users;
}
 */


//****************************************************** */
// *****************************************************************************************
// **** get meals types
export function getmealTypes() {
  return (dispatch) => {
    console.log("getmealTypesBegin");
    dispatch(getmealTypesBegin());

    if (store.getState().globalListsReducer.mealtypes.length > 0)
      return;

    return axios.get(
      CONST_APIURLKUSALA +
      "dklaccueil/dictionary/mealtypes?sortorder=ASC&limit=100&active=1" +
      "&DOLAPIKEY=" +
      getUserToken(),
    )
      .then((json) => {
        console.log("getmealTypesBeginSuccess");
        // dispatch(getintakeplacesSuccess(json.data));
        let mealtypess = json.data.map(mealtypes => mealtypes != false && mealtypes.label.length > 0 &&
          { "label": mealtypes.label, "rowid": mealtypes.rowid })
        dispatch(getmealTypesSuccess(mealtypess));

      })
      .catch((error) => {
        console.log("getmealTypesBeginFailure");
        // *** an 404 error is sent when Dolibarr didn't find invoices
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(getmealTypesFailure({ code: "600", message: "Veuillez vous authentifier" }));
          } else
            if (error.response.status === 404) {
              dispatch(getmealTypesSuccess([]));
            } else {
              dispatch(getmealTypesFailure(error.response.data.error));
            }
        } else {
          // *** It's an API error
          dispatch(getmealTypesFailure(error));
        }
      });
  };
}

export const GET_MEALTYPES_BEGIN = "GET_MEALTYPES_BEGIN";
export const GET_MEALTYPES_SUCCESS = "GET_MEALTYPES_SUCCESS";
export const GET_MEALTYPES_FAILURE = "GET_MEALTYPES_FAILURE";

export const getmealTypesBegin = () => ({
  type: GET_MEALTYPES_BEGIN,
});

export const getmealTypesSuccess = (mealtypes) => ({
  type: GET_MEALTYPES_SUCCESS,
  payload: { mealtypes },
});

export const getmealTypesFailure = (error) => ({
  type: GET_MEALTYPES_FAILURE,
  payload: { error },
});

