import {
  CONST_APIURLKUSALA,
} from "../../Constants.js";
import axios from "axios";
import { store } from "../../App"
import { getUserToken } from "../Login/LoginActions";

export function getProducts(searchString) {
  return (dispatch) => {
    console.log("getProductsBegin : " + searchString);
    dispatch(getProductsBegin());
    //
    if (store.getState().commonProductReducer.products.length > 0)
      return;
    return (
      axios
        .get(
          CONST_APIURLKUSALA +
          "products" +

          "?DOLAPIKEY=" +
          getUserToken(),
          {
            params: {
              sortfield: "ref",
              limit: 1000,
            },
          }
        )
        .then((json) => {
          console.log("getProductsSuccess : " + json.data.length + " results");

          // *** Keep only needed data
          let products = [];
          json.data.map((product, index) => {
            let newProd = new Object();
            newProd.id = product.id;
            newProd.ref = product.ref;
            newProd.label = product.label;
            newProd.type = product.type;
            newProd.array_options = product.array_options;
            newProd.description = product.description;
            newProd.multiprices_ttc = product.multiprices_ttc;
            newProd.accountancy_code_sell = product.accountancy_code_sell;
            newProd.multiprices_ttc = product.multiprices_ttc;
            newProd.status = product.status;
            products.push(newProd);
          });
          dispatch(getProductsSuccess(products));

        })
        .catch((error) => {
          console.log("getProductsFailure");
          // *** 
          if (error.response) {
            // *** It's a Dolibarr error
            if (error.response.status === 404)
              dispatch(getProductsSuccess([]));
            else dispatch(getProductsFailure(error.response.data.error));
          } else {
            // *** It's an API error
            dispatch(getProductsFailure(error));
          }
        })
    );
  };
}

export const GET_PRODUCTS_BEGIN = "GET_PRODUCTS_BEGIN";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAILURE = "GET_PRODUCTS_FAILURE";

export const getProductsBegin = () => ({
  type: GET_PRODUCTS_BEGIN,
});

export const getProductsSuccess = (products) => ({
  type: GET_PRODUCTS_SUCCESS,
  payload: { products },
});

export const getProductsFailure = (error) => ({
  type: GET_PRODUCTS_FAILURE,
  payload: { error },
});


/**
 * Return the ressource product
 * resource are special products used in the orderlines
 */
export function getAllProducts() {
  return store.getState().commonProductReducer.products.filter(product => product.type === '1');
}

/**
 * Return the ressource product RES : rooms 
 * resource are special products used in the orderlines
 * 
 */
export function getResourceProducts() {
  return store.getState().commonProductReducer.products.filter(product => product.ref.startsWith("RES"));
}

/**
 * Return the meal ressource product : Meal types
 * resource are special products used in the orderlines
 */
export function getMealsResourceProducts() {
  return store.getState().commonProductReducer.products.filter(product => product.ref.startsWith("RER"));
}

/**
 * Return the hosting product
 */
export function getHostingProducts() {
  return store.getState().commonProductReducer.products.filter(product => product.ref.startsWith("HEB"));
}

/**
 * Return the hosting product
 */
export function getHostingActiveProducts() {
  // if (store.getState().commonProductReducer.products &&
  //  store.getState().commonProductReducer.products.length > 0)
  return store.getState().commonProductReducer.products.filter(product => product.ref.startsWith("HEB") && product.status === '1');
  //else
  //  throw new Error("La liste des produits est vide");
}
/**
 * Return the hosting product
 */
export function getTranslationProducts() {
  let test = store.getState().commonProductReducer.products.filter(product => product.ref.startsWith("TRA"));
  return store.getState().commonProductReducer.products.filter(product => product.ref.startsWith("TRA"));
}

/**
 * Return the meal  product
 */
export function getMealProducts() {

  return store.getState().commonProductReducer.products.filter(product => product.ref.startsWith("REP"));
}

/**
 * Return the subscription product
 */
export function getSubscriptionProducts() {
  // let test = store.getState().commonProductReducer.products.filter(product => product.ref.startsWith("ADH"));
  // let test2 = store.getState().commonProductReducer.products;
  return store.getState().commonProductReducer.products.filter(product => product.ref.startsWith("ADH"));
}
/**
 * Return the restaurant product
 */
export function getRestaurantProducts() {
  return store.getState().commonProductReducer.products.filter(product => product.ref.startsWith("REP"));
}

/**
 * Return the session product
 */
export function getSessionProducts() {
  // let products = store.getState().commonProductReducer.products.filter(
  //  product => ((product.ref.startsWith("STA"))));
  // let test = store.getState().commonProductReducer.products;
  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() - 1);

  return store.getState().commonProductReducer.products.filter(
    product => ((product.ref.startsWith("STA") && product.array_options.options_sta_published === '1'
      && (new Date(product.array_options.options_sta_datefin * 1000).setHours(0, 0, 0, 0) >= tomorrow.setHours(0, 0, 0, 0)))
    ))

}
/**
 * get product from refe
 */
export function getProductFromRef(ref) {
  return store.getState().commonProductReducer.products.filter(product => product.ref.startsWith(ref));

}

/** @TODO : à reprendre
 * get product from ref
 */
export function getProductFromId(id) {
  // let test = store.getState().commonProductReducer.products;
  // let test2 = store.getState().commonProductReducer.products.filter(product => product.id === id);
  if (store.getState().commonProductReducer.products.filter(product => product.id === id).length > 0)
    return store.getState().commonProductReducer.products.filter(product => product.id === id)[0];
  else
    return null
}