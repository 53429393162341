import {

  GET_OPENMEALSFORADAYRANGE_INIT,
  GET_OPENMEALSFORADAYRANGE_BEGIN,
  GET_OPENMEALSFORADAYRANGE_SUCCESS,
  GET_OPENMEALSFORADAYRANGE_FAILURE

} from "./RestaurantActions";

const initialState = {
  bookingLines: [],
  tabFullResult: [],
  tabFullAggregatedResult: [],
  maxMealsForADayValues: [],
  openMealsForADayRangeValues: [],
  newloading: false,
  loading: false,
  error: null,
};

export default function RestaurantReducer(state = initialState, action) {
  switch (action.type) {
    /***  */

    case GET_OPENMEALSFORADAYRANGE_INIT:
      return {
        ...state,
        loading: true,
        newwerror: null,
        openMealsForADayRangeValues: []
      };

    case GET_OPENMEALSFORADAYRANGE_BEGIN:
      return {
        ...state,
        loading: true,
        newwerror: null,
        openMealsForADayRangeValues: []
      };

    case GET_OPENMEALSFORADAYRANGE_SUCCESS:
      return {
        ...state,
        loading: false,
        openMealsForADayRangeValues: action.payload.openMealsForADayRangeValues
      };

    case GET_OPENMEALSFORADAYRANGE_FAILURE:
      return {
        ...state,
        loading: false,
        newrror: action.payload.newerror,
        maxMealsForADayValues: []
      };

    default:
      return state;
  }
}
