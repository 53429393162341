//import {
//  CONST_APIURLKUSALA,
//} from "../../Constants.js";
//import axios from "axios";
//import { store } from "../../App.js";
//import { getUserToken } from "../Login/LoginActions";
//import { getProductFromId } from "../CommonProduct/CommonProductActions"
// import { getCustomer } from "./CustomerActions"
import { getConfigurationValue } from "../GlobalLists/GlobalListsActions";



/****************************************************************************************** */
// *** Needs engine   **************************************************/


/**
  * When the user choose a course, we have to prepare the display of the course checkboses, all course participation ... 
  * Build the registration data structure
  * @param {*} productTemp
  * @param (*) checkDefaultState : the ckeckboxes default state (true or false).
  * @return : 
  */
export function computeChosenCourse(productTemp, checkDefaultState) {
  // 
  // setlocalError("");
  let registration = {};
  registration.product = productTemp;
  registration.mealType = "";
  registration.hostingType = "";
  registration.translist = [];
  // registration.donationamount = null;

  // *** Compute course days number
  registration.datedebutStr = productTemp.array_options['options_sta_datedebut'];
  registration.datefinStr = productTemp.array_options['options_sta_datefin'];
  registration.datedebut = new Date(registration.datedebutStr * 1000);
  registration.datefin = new Date(registration.datefinStr * 1000);
  registration.sta_hostingclosed = productTemp.array_options['options_sta_hostingclosed'];
  if (registration.datedebutStr !== '' && registration.datefinStr !== '') {
    registration.nbJoursStage = Math.floor((((registration.datefin).getTime() - (registration.datedebut).getTime()) / (1000 * 3600 * 24)) + 1 + 2);
  } else {
    throw Error("Dates de stage invalides")
  }

  registration.datedebut = new Date(registration.datedebut.getTime() - (1000 * 3600 * 24));
  registration.datefin = new Date(registration.datefin.getTime() + (1000 * 3600 * 24));

  // *** Compute day's dates
  registration.tabBusyLigneColonne = new Array(registration.nbJoursStage);
  for (let ligne = 0; ligne < registration.nbJoursStage; ++ligne) {
    let datedebutemp = new Date(registration.datedebut);
    registration.tabBusyLigneColonne[ligne] = new Date(datedebutemp.setDate(registration.datedebut.getDate() + ligne)).getTime();
  }

  // *** participation
  registration.tabBusyParticipation = new Array(registration.nbJoursStage);
  registration.tabBusyParticipation.fill(true);
  registration.tabBusyParticipation[0] = null;
  registration.tabBusyParticipation[registration.tabBusyParticipation.length - 1] = null;

  // *** petit déjeuners
  registration.veggie = checkDefaultState;

  registration.tabBusyBreakfast = new Array(registration.nbJoursStage);
  registration.tabBusyBreakfast.fill(checkDefaultState);
  registration.tabBusyBreakfast[0] = null;


  // *** déjeuner
  registration.tabBusyLunch = new Array(registration.nbJoursStage);
  registration.tabBusyLunch.fill(checkDefaultState);
  registration.tabBusyLunch[0] = null;
  registration.tabBusyLunch[registration.tabBusyLunch.length - 1] = null;
  // *** diner
  registration.tabBusyDiner = new Array(registration.nbJoursStage);
  registration.tabBusyDiner.fill(checkDefaultState);
  registration.tabBusyDiner[registration.tabBusyDiner.length - 1] = null;

  // *** hosting
  // if (registration.sta_hostingclosed !== '1') {
  registration.tabBusyHosting = new Array(registration.nbJoursStage);
  registration.tabBusyHosting.fill(checkDefaultState);
  registration.tabBusyHosting[registration.tabBusyHosting.length - 1] = null;

  registration.hostingtype = -1;
  registration.translationlanguage = null;
  // }
  // *** Log registration object
  // console.log(JSON.stringify(registration));

  return registration;
  // 

}


/**
 * Compute the registration structure for a session and returns the order lines to be inserted in the order 
 * @param {*} registration 
 * @returns : an array of order lines
 */
export function computeRegistrationv2(registration) {

  // *** Check configuration
  if (!getConfigurationValue('MMealDay')
    || !getConfigurationValue('PMealDay')
    || !getConfigurationValue('SMealDay')
    || !getConfigurationValue('RefTaxes')) {
    // setlocalError("Configuration manquante, impossible de calculer, veuillez contacter l'administrateur");
    return null;
  }
  let computedOrderlines = [];
  let transStartDate = '';
  let transEndDate = '';
  let transQty = 0;
  let computeddintakeplace;
  if (registration.intakeplace !== null && typeof registration.intakeplace === "object")
    computeddintakeplace = registration.intakeplace.rowid;
  else
    computeddintakeplace = '';
  // *** participation  **************************************************
  let startItem = -1;
  registration.tabBusyParticipation.forEach(function (item, index, array) {
    if (item) {
      if (startItem === -1) {
        startItem = index;
      } else {
        // if (startItem > -1) { //  && index === registration.tabBusyParticipation.length - 1
        //   console.log("Période : " + new Date(registration.tabBusyLigneColonne[startItem]) + " - " + new Date(registration.tabBusyLigneColonne[index - 1]));
        //   startItem = -1;
        //  }
      }
    } else {
      if (startItem > -1) {
        transStartDate = registration.tabBusyLigneColonne[startItem] / 1000;
        transEndDate = registration.tabBusyLigneColonne[index - 1] / 1000;
        transQty = index - startItem;

        let newLine = {
          "fk_product": registration.product.id,
          "array_options": {
            "options_lin_datedebut": registration.tabBusyLigneColonne[startItem] / 1000,
            "options_lin_datefin": registration.tabBusyLigneColonne[index - 1] / 1000,
            "options_lin_room": null

          },
          "qty": index - startItem,
          "subprice": registration.product.multiprices_ttc[1]
        }
        computedOrderlines.push(newLine);
        startItem = -1;
      }
    }
  });


  if (startItem > -1) {
    let newLine = {
      "fk_product": registration.product.id,
      "array_options": {
        "options_lin_datedebut": registration.tabBusyLigneColonne[startItem] / 1000,
        "options_lin_datefin": registration.tabBusyLigneColonne[registration.tabBusyParticipation.length - 1] / 1000,
        "options_lin_room": null,
        "lin_intakeplace": computeddintakeplace
      },
      "qty": registration.tabBusyParticipation.length - startItem,
      "subprice": registration.product.multiprices_ttc[1]
    }
    computedOrderlines.push(newLine);
  }

  // *** Repas **************************************************
  // *** Breakfast  
  startItem = -1;
  registration.tabBusyBreakfast.forEach(function (item, index, array) {
    if (item) {
      if (startItem === -1) {
        startItem = index;
      } else {
        // if (startItem > -1) { //  && index === registration.tabBusyParticipation.length - 1
        //   console.log("Période : " + new Date(registration.tabBusyLigneColonne[startItem]) + " - " + new Date(registration.tabBusyLigneColonne[index - 1]));
        //   startItem = -1;
        //  }
      }
    } else {
      if (startItem > -1) {
        let newLine = {
          "fk_product": getConfigurationValue('PMealDay'),
          "array_options": {
            "options_lin_datedebut": registration.tabBusyLigneColonne[startItem] / 1000,
            "options_lin_datefin": registration.tabBusyLigneColonne[index - 1] / 1000,
            "options_lin_room": registration.mealType.rowid,
            "lin_intakeplace": computeddintakeplace
            //, "options_lin_mealtype": null
          },
          "qty": index - startItem,
          "subprice": registration.product.multiprices_ttc[1]
        }
        computedOrderlines.push(newLine);
        startItem = -1;
      }
    }
  });

  if (startItem > -1) {
    let newLine = {
      "fk_product": getConfigurationValue('PMealDay'),
      "array_options": {
        "options_lin_datedebut": registration.tabBusyLigneColonne[startItem] / 1000,
        "options_lin_datefin": registration.tabBusyLigneColonne[registration.tabBusyParticipation.length - 1] / 1000,
        "options_lin_room": registration.mealType.rowid,
        "lin_intakeplace": computeddintakeplace
      },
      "qty": registration.tabBusyParticipation.length - startItem,
      "subprice": registration.product.multiprices_ttc[1]
    }
    computedOrderlines.push(newLine);
  }

  // *** lunch  
  startItem = -1;
  registration.tabBusyLunch.forEach(function (item, index, array) {
    if (item) {
      if (startItem === -1) {
        startItem = index;
      } else {
        // if (startItem > -1) { //  && index === registration.tabBusyParticipation.length - 1
        //   console.log("Période : " + new Date(registration.tabBusyLigneColonne[startItem]) + " - " + new Date(registration.tabBusyLigneColonne[index - 1]));
        //   startItem = -1;
        //  }
      }
    } else {
      if (startItem > -1) {
        let newLine = {
          "fk_product": getConfigurationValue('MMealDay'),
          "array_options": {
            "options_lin_datedebut": registration.tabBusyLigneColonne[startItem] / 1000,
            "options_lin_datefin": registration.tabBusyLigneColonne[index - 1] / 1000,
            "options_lin_room": registration.mealType.rowid,
            "lin_intakeplace": computeddintakeplace
          },
          "qty": index - startItem,
          "subprice": registration.product.multiprices_ttc[1]
        }
        computedOrderlines.push(newLine);
        startItem = -1;
      }
    }
  });

  if (startItem > -1) {
    let newLine = {
      "fk_product": getConfigurationValue('MMealDay'),
      "array_options": {
        "options_lin_datedebut": registration.tabBusyLigneColonne[startItem] / 1000,
        "options_lin_datefin": registration.tabBusyLigneColonne[registration.tabBusyParticipation.length - 1] / 1000,
        "options_lin_room": registration.mealType.rowid,
        "lin_intakeplace": computeddintakeplace
      },
      "qty": registration.tabBusyParticipation.length - startItem,
      "subprice": registration.product.multiprices_ttc[1]
    }
    computedOrderlines.push(newLine);
  }

  // *** Diner  
  startItem = -1;
  registration.tabBusyDiner.forEach(function (item, index, array) {
    if (item) {
      if (startItem === -1) {
        startItem = index;
      } else {
        // if (startItem > -1) { //  && index === registration.tabBusyParticipation.length - 1
        //   console.log("Période : " + new Date(registration.tabBusyLigneColonne[startItem]) + " - " + new Date(registration.tabBusyLigneColonne[index - 1]));
        //   startItem = -1;
        //  }
      }
    } else {
      if (startItem > -1) {
        let newLine = {
          "fk_product": getConfigurationValue('SMealDay'),
          "array_options": {
            "options_lin_datedebut": registration.tabBusyLigneColonne[startItem] / 1000,
            "options_lin_datefin": registration.tabBusyLigneColonne[index - 1] / 1000,
            "options_lin_room": registration.mealType.rowid,
            "lin_intakeplace": computeddintakeplace
          },
          "qty": index - startItem,
          "subprice": registration.product.multiprices_ttc[1]
        }
        computedOrderlines.push(newLine);
        startItem = -1;
      }
    }
  });

  if (startItem > -1) {
    let newLine = {
      "fk_product": getConfigurationValue('SMealDay'),
      "array_options": {
        "options_lin_datedebut": registration.tabBusyLigneColonne[startItem] / 1000,
        "options_lin_datefin": registration.tabBusyLigneColonne[registration.tabBusyParticipation.length - 1] / 1000,
        "options_lin_room": registration.mealType,
        "lin_intakeplace": computeddintakeplace
      },
      "qty": registration.tabBusyParticipation.length - startItem,
      "subprice": registration.product.multiprices_ttc[1]
    }
    computedOrderlines.push(newLine);
  }


  // *** Hébergement **************************************************
  let startItemHosting = -1;
  registration.tabBusyHosting.forEach(function (item, index, array) {
    if (item) {
      if (startItemHosting === -1) {
        startItemHosting = index;
      } else {
        // if (startItem > -1) { //  && index === registration.tabBusyParticipation.length - 1
        //   console.log("Période : " + new Date(registration.tabBusyLigneColonne[startItem]) + " - " + new Date(registration.tabBusyLigneColonne[index - 1]));
        //   startItem = -1;
        //  }
        console.log("Hosting : cas de startItemHosting ===-1, nous n'avons pas prévu de traitement, Est-ce une erreur ?");
      }
    } else {
      if (startItemHosting > -1) {
        // *** Insert hosting
        computedOrderlines.push({
          "fk_product": registration.hostingType.id,
          "array_options": {
            "options_lin_datedebut": registration.tabBusyLigneColonne[startItemHosting] / 1000,
            "options_lin_datefin": registration.tabBusyLigneColonne[index - 1] / 1000,
            "options_lin_room": null, "options_roomid": "0"
          },
          "qty": index - startItemHosting
        });


        // *** Insert Taxes
        computedOrderlines.push({
          "fk_product": getConfigurationValue('RefTaxes'),// '372',
          "array_options": {
            "options_lin_datedebut": registration.tabBusyLigneColonne[startItemHosting] / 1000,
            "options_lin_datefin": registration.tabBusyLigneColonne[index - 1] / 1000,
            "options_lin_room": null, "options_roomid": "0"
          },
          "qty": index - startItemHosting
        });

        startItemHosting = -1;
      }
    }

  });
  if (startItemHosting > -1) {
    // *** Insert hosting
    computedOrderlines.push({
      "fk_product": registration.hostingType.id,
      "array_options": {
        "options_lin_datedebut": registration.tabBusyLigneColonne[startItemHosting] / 1000,
        "options_lin_datefin": registration.tabBusyLigneColonne[registration.tabBusyParticipation.length - 1] / 1000,
      },
      "qty": registration.tabBusyParticipation.length - startItemHosting
    });


    // *** Insert Taxes
    computedOrderlines.push({
      "fk_product": getConfigurationValue('RefTaxes'),// '372',
      "array_options": {
        "options_lin_datedebut": registration.tabBusyLigneColonne[startItemHosting] / 1000,
        "options_lin_datefin": registration.tabBusyLigneColonne[registration.tabBusyParticipation.length - 1] / 1000,
      },
      "qty": registration.tabBusyParticipation.length - startItemHosting
    });

  }


  // ** Translation language

  if (registration.translationlanguage !== null) {
    // *** Insert Taxes
    computedOrderlines.push({
      "fk_product": registration.translationlanguage.id,// '372',
      "array_options": {
        "options_lin_datedebut": transStartDate,
        "options_lin_datefin": transEndDate,
        "options_lin_room": null
      },
      "qty": transQty
    });

  }
  return computedOrderlines;
}


