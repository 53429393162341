import {
  GET_PRODUCT_BEGIN,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAILURE,
  GET_PRODUCTREGISTERED_BEGIN,
  GET_PRODUCTREGISTERED_SUCCESS,
  GET_PRODUCTREGISTERED_FAILURE,
  GET_PRODUCTREGISTERED_INIT,
  GET_PRODUCTFULLREGISTERED_BEGIN,
  GET_PRODUCTFULLREGISTERED_SUCCESS,
  GET_PRODUCTFULLREGISTERED_FAILURE,
  GET_PRODUCTFULLREGISTERED_INIT


} from "./ProductActions";

const initialState = {
  product: { id: "" },
  productregistered: [],
  loading: false,
  error: "",
  productFullregistered: [],
};

export default function ProductReducer(state = initialState, action) {
  switch (action.type) {

    // *** Get product
    case GET_PRODUCT_BEGIN:
      return {
        ...state,
        loading: true,
        productregistered: [],
      };

    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        product: action.payload.product,
        productregistered: [],
      };

    case GET_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };


    // *** Get registered
    case GET_PRODUCTREGISTERED_INIT:
      return {
        ...state,
        loading: false,
        productregistered: [],
      };

    case GET_PRODUCTREGISTERED_BEGIN:
      return {
        ...state,
        loading: true,
        productregistered: [],
      };

    case GET_PRODUCTREGISTERED_SUCCESS:
      return {
        ...state,
        loading: false,
        productregistered: action.payload.productregistered,
      };

    case GET_PRODUCTREGISTERED_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };


    // *** Get registered
    case GET_PRODUCTFULLREGISTERED_INIT:
      return {
        ...state,
        loading: false,
        productFullregistered: [],
      };

    case GET_PRODUCTFULLREGISTERED_BEGIN:
      return {
        ...state,
        loading: true,
        productFullregistered: [],
      };

    case GET_PRODUCTFULLREGISTERED_SUCCESS:
      return {
        ...state,
        loading: false,
        productFullregistered: action.payload.productFullregistered,
      };

    case GET_PRODUCTFULLREGISTERED_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
