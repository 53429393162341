import React from "react";

// import { Link } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import {
    CircularProgress,
    Typography,
    Divider
} from "@material-ui/core";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Box from "@material-ui/core/Box"
import Backdrop from "@material-ui/core/Backdrop";

// *** Redux
import { useDispatch } from 'react-redux';
import { shallowEqual, useSelector } from 'react-redux';
import { useEffect } from "react"
import { useTranslation } from "react-i18next";
// import { useParams } from "react-router-dom";

// *** Zopa components
import IdentityForm from "./IdentityForm"
import ValidationForm from "./ValidationForm"
import SendForm from "./SendForm"
import LanguageSelector from "../LanguageSelector/LanguageSelector"

// import RegistrationCalendar from "./RegistrationCalendar"
import CgvForm from "./CgvForm"


import { getConfiguration, getDolibarrStatus, getIncomesLevelTable, getCustomerCivilitiesTable, getmealTypes } from "../../services/GlobalLists/GlobalListsActions";
import { getProducts } from "../../services/CommonProduct/CommonProductActions"
import pkg from '../../../package.json';
import NeedsForm from "./NeedsForm"
// import OrderContent from "./OrderContent"

// *** Styling
import clsx from "clsx";
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles((theme) => ({
    root: {
        background: "linear-gradient(45deg, #F4F6F6 30%, #F2F3F4 90%)",
        padding: theme.spacing(2),
    },
    pageTitle: {
        color: "#6b6c6d",
        align: "center"
    }
    ,
    blockCardHeaderStyle:
        theme.palette.blockCardHeaderStyle,


    blockCardHeaderIcon:
        theme.palette.blockCardHeaderIcon

}));

/**
 * Apply form is the main component of Zopaweb, it includes all the others components
 * The others are : Needs, identity, validation, send and cgv
 * @param {*} props 
 * @returns 
 */
export default function ApplyForm(props) {
    const { ...rest } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    // const { t, i18n } = useTranslation('common');
    const { t } = useTranslation();

    const error = useSelector(state => state.registrationReducer.error, shallowEqual);
    const globalStatusError = useSelector(state => state.globalListsReducer.error, shallowEqual);
    const loading = useSelector(state => state.globalListsReducer.loading, shallowEqual);
    const message = useSelector(state => state.registrationReducer.message, shallowEqual);
    const dolibarrstatus = useSelector(state => state.globalListsReducer.dolibarrstatus, shallowEqual);
    const configuration = useSelector(state => state.globalListsReducer.configuration, shallowEqual);
    const sendingError = useSelector(state => state.registrationReducer.sendingError, shallowEqual);
    useEffect(() => {
        dispatch(getDolibarrStatus());
        dispatch(getConfiguration());
        dispatch(getProducts());
        dispatch(getCustomerCivilitiesTable());
        dispatch(getIncomesLevelTable(true));
        dispatch(getmealTypes());

        // dispatch(getCountriesTable());
    }, []);

    return (
        <div className={classes.root}><Box display="flex" alignItems="center"
            justifyContent="center">
            <Typography variant="h3" component="h3" className={classes.pageTitle} >
                {t('intro.label')}</Typography>


        </Box>
            <Divider style={{ marginBottom: "0.5em" }} />
            <img src="zopawebheaderimagefile.jpg" style={{ width: "100%", marginTop: "1em" }} />

            {error && <Alert severity="error">Erreur : {error}</Alert>}

            <Grid spacing={1} item md={12} xs={12} container
                direction="row-reverse"
                justify="center"
                alignItems="center"
                style={{ padding: "10px" }}>
                <Grid item md={10} xs={12}>
                    <Grid container alignItems="flex-start" spacing={2}>
                        <Grid item md={12} xs={12}>
                            {loading && <Backdrop className={classes.backdrop} >
                                <CircularProgress color="inherit" />
                            </Backdrop>}

                            {error && <Alert style={{ marginBottom: "20" }} severity="error">Erreur : {error.message}</Alert>}
                            {message && <Alert style={{ marginBottom: "20" }} severity="success">Information : {message.message}</Alert>}
                            {globalStatusError && <Alert style={{ marginBottom: "20" }} severity="error">Erreur : {globalStatusError.message}</Alert>}
                            {sendingError && <Alert style={{ marginBottom: "20" }} severity="error">Erreur : {sendingError.message}</Alert>}


                        </Grid>
                    </Grid>

                    <Grid container alignItems="flex-start" spacing={2}>
                        <Grid item md={12} xs={12} style={{ margin: "10px" }}>
                            <Typography align="center" style={{ marginBottom: "10px" }}> <LanguageSelector /></Typography>
                            <Typography align="left">
                                {t('intro.help')}
                            </Typography>

                        </Grid>

                    </Grid>

                    {/* <RegistrationCalendar /> */}
                    <Card style={{ margin: "10px" }} >
                        <CardHeader title={t('step1.label')} subheader="" style={{ backgroundColor: "#DFDFDB" }}>
                        </CardHeader>
                        <CardContent>
                            <Grid container
                                justify="center"
                                alignItems="center" spacing={1} style={{ marginTop: "20px", padding: "5px" }} >
                                <NeedsForm />
                            </Grid>
                        </CardContent>
                    </Card>

                    <IdentityForm />
                    <ValidationForm />

                    <SendForm />
                    <CgvForm />
                    <Divider />
                </Grid>
            </Grid>
            <Typography variant="body1">
                &copy;{" "}
                Zöpa Web - version {pkg.version} - {pkg.compiledDate}
            </Typography>

            {dolibarrstatus && dolibarrstatus.success && <Typography variant="caption" > Communication serveur Dolibarr Ok - Version Dolibarr : {dolibarrstatus.success.dolibarr_version}</Typography>}
            {dolibarrstatus && !dolibarrstatus.success && <Typography variant="caption" color="error" > Communication serveur Dolibarr HS  </Typography>}
            {!configuration && <Typography variant="caption" color="error" > - Erreur de lecture du fichier de configuration  </Typography>}
            <div><Typography variant="caption" >React version: {React.version}</Typography></div>

            <Typography variant="caption">
                Powered by Dhagpo Kagyu Ling
            </Typography>
        </div>
    );

}

