import React from "react";

import Typography from "@material-ui/core/Typography";
import { Card, CardContent } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { useTranslation } from "react-i18next";

// *** Redux


// *** Zopa components
// *** Styling
import { makeStyles } from '@material-ui/core/styles'
// const words = ;
const useStyles = makeStyles((theme) => ({
    root: {
        background: "linear-gradient(45deg, #F4F6F6 30%, #F2F3F4 90%)",
        padding: theme.spacing(2),
    },
    pageTitle: { color: "#6b6c6d" },
    blockCardHeaderStyle:
        theme.palette.blockCardHeaderStyle,
    blockCardHeaderIcon:
        theme.palette.blockCardHeaderIcon
}));

export default function ReturnNok(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    // const ErrorString = (window.location.search).split('&').find(tree => tree.startsWith("Erreur"));
    // const erreur = ErrorString.substring(ErrorString.indexOf('=') + 1, ErrorString.length);
    // let errorStringArray = [
    //     { code: "00001", msg: "Le serveur ne peux par répondre à votre demande, veuillez ré-éssayer plus tard" }
    // ];
    // const ErrorStringtoDisplay = errorStringArray.filter(
    //     function (post, index) {
    //         if (post.code == erreur)
    //             return post.msg;
    //     });

    //const params = useParams();
    // let [searchParams, setSearchParams] = useSearchParams()
    // const Erreur = searchParams.get("Erreur")
    // { retour, Mt, Ref, Auto, Erreur, heure }
    // https://dev.zopa.dhagpo.org/?retour=ok&Mt=4400&Ref=FA2206-2252&Auto=XXXXXX&Erreur=00000&heure=15%3A11%3A27
    function refreshPage() {
        window.location.assign("/");
    }

    return (
        <div className={classes.root}><Box display="flex" alignItems="center"
            justifyContent="center">
            <Typography variant="h3" component="h3" className={classes.pageTitle} >
                Bienvenue sur Zöpa, l'outil d'inscription en ligne de Dhagpo Kagyu Ling </Typography>
        </Box>
            <img src="zopawebheaderimagefile.jpg" style={{ width: "100%", marginTop: "1em" }} />

            <Card style={{ margin: "10px" }} >

                <CardContent>
                    <Grid spacing={1} item md={12} xs={12} container
                        direction="row-reverse"
                        justify="center"
                        alignItems="center"
                        style={{ padding: "10px" }}>
                        <Grid spacing={2} item md={8} xs={12}>
                            <Grid container alignItems="flex-start" spacing={2}>
                                <Grid item md={12} xs={12}>
                                    <Alert severity="error">
                                        <AlertTitle>Attention</AlertTitle>
                                        <Typography style={{ fontSize: '1rem' }}>
                                            Nous n'avons pas pu valider votre paiement sur la plate-forme Up2Pay. Veuillez contacter l'accueil de Dhagpo.  Si vous souhaitez réserver une autre inscription, veuillez cliquer sur le bouton ci-dessous.
                                        </Typography>
                                        <Typography style={{ fontSize: '1rem' }}>
                                            {/* L'erreur renvoyée par le serveur de paiement up2pay est : {ErrorStringtoDisplay} */}

                                        </Typography>                                     </Alert>
                                    <Button onClick={refreshPage} color="primary" style={{ marginTop: "20px", marginLeft: "20px" }} variant="outlined" >Autre inscription</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
}

